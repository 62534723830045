import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BrandHeader from "../../components/brand-headers/shoeangelBrandHeader"
import BrandBody from "../../components/brand-bodies/shoeangelBrandBody"

const ShoeAngelPage = ({ pageContext, location }) => {
  const pageTitle = "Shoe Angel"

  return (
    <>
      <Layout>
        <Seo title={pageTitle} />
        <BrandHeader />
        <BrandBody />
      </Layout>
      {/* description for search, hidden from page */}
      <p
        data-typesense-field="description"
        style={{ display: `none` }}
        aria-hidden="true"
      >
        Shoe Angel partners with healthcare facilities to help ensure their
        staff and associates are wearing proven slip-resistant comfort footwear
        specifically designed for medical environments and containments. As a
        footwear benefit program for their associates, medical facilities help
        reduce slips and falls, the number two leading cause of injury in the
        healthcare industry.
      </p>
    </>
  )
}

export default ShoeAngelPage

//head element
export const Head = () => (
  <>
    <title data-typesense-field="title">Shoe Angel</title>
    <meta
      name="description"
      content="Shoe Angel partners with healthcare facilities to help ensure their staff and associates are wearing proven slip-resistant comfort footwear specifically designed for medical environments and containments. As a footwear benefit program for their associates, medical facilities help reduce slips and falls, the number two leading cause of injury in the healthcare industry."
    />
  </>
)
