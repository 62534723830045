import React from "react"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import * as brandBodyStyles from "./BrandBody.module.scss"
import { Link } from "gatsby"

const BrandBody = ({}) => {
  return (
    <div className={brandBodyStyles.brandBody}>
      <div className={brandBodyStyles.about}>
        <div className={brandBodyStyles.info}>
          <h2>About Shoe Angel</h2>
          <p>Shoe Angel partners with healthcare facilities to help ensure their staff and associates are wearing proven slip-resistant comfort footwear specifically designed for medical environments and containments. As a footwear benefit program for their associates, medical facilities help reduce slips and falls, the number two leading cause of injury in the healthcare industry.</p>
        </div>
        <div className={brandBodyStyles.rightImage}>
          <StaticImage
            src="../../assets/images/index/brand-headers/rocky/factory-sepia.jpg"
            className={brandBodyStyles.halfImage}
            alt="Rocky factory 1933"
            imgStyle={{width: `100%`, height: `100%`}}
            style={{width: `100%`, height: `100%`}}
          />
        </div>
      </div>
      {/* <div className={brandBodyStyles.highlightSection}>
        <div className={brandBodyStyles.imageGrid}>
            <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-outdoor-square.jpg"
                className={brandBodyStyles.halfImage}
                alt="rocky outdoor"
                imgStyle={{width: `100%`, height: `100%`, padding: '20px 10px 10px 20px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-work-square.jpg"
                className={brandBodyStyles.halfImage}
                alt="rocky work"
                imgStyle={{width: `100%`, height: `100%`, padding: '20px 20px 10px 10px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-western-square.jpg"
                className={brandBodyStyles.halfImage}
                alt="rocky western"
                imgStyle={{width: `100%`, height: `100%`, padding: '10px 10px 20px 20px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-psd-commil-square.jpg"
                className={brandBodyStyles.halfImage}
                alt="rocky public service and commercial military"
                imgStyle={{width: `100%`, height: `100%`, padding: '10px 20px 20px 10px'}}
                style={{width: `100%`, height: `100%`}}
              />
          </div>
          <div className={brandBodyStyles.highlights}>
            <h3>Highlights</h3>
            <h4>Foundation of Wellness</h4>
            <p>Feet are the structural foundation of the body and essential to overall health &amp; wellness. Long stressful shifts on your feet can cause debilitating ailments to the entire body. A Shoe Angel program provides assessment tools such as a 3D Foot Scanner, condition-built footwear, Aetrex custom orthotics &amp; OS1st medical-grade compression socks to correct and protect. We help healthcare facilities decrease slips and falls, lost-time cases &amp; increase productivity to feel good on and off work.</p>
            <h4>Do Good. Feel Good.</h4>
            <p>A portion of every purchase is given to an account's chosen charity. Charities have included hospital foundations and The Ronald McDonald House. The Adams County Regional Medical Center purchased medical equipment with the help of donations to their internal foundation.</p>
            <h4>Customized Website</h4>
            <p>Through a dedicated and password-protected website, we display only products a facility chooses to show their employees with 24/7 access from any device, free shipping and returns and a 60-day guarantee.</p>
          </div>
      </div> */}
      <div className={brandBodyStyles.strategy}>
        <h4>Shoe Angel Strategy</h4>
        <p>Shoe Angel was developed to assist healthcare facilities in lowering their incidents of injuries due to slippery contaminated floors. We offer a managed safety footwear program of well-known brands designed and developed specifically for the environment and worker's demands. Our goal is to help protect the feet of the healthcare industry for overall body wellness and patient protection. We give back a portion of every purchase to embrace the heart of the industry because when you do good, you feel good.</p>
        <div className={brandBodyStyles.strategyButtons}>
          <Link to="/customer-resources/for-our-consumers/" title="Customer Resources" className="CTAalt">
            Customer Resources
          </Link>
        </div>
      </div>
    </div>
  )
}

export default BrandBody
